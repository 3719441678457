/* Tooth 11 */
.tooth-container-11 {
  width: 6.467%;
}

.tooth-container-large-11 {
  width: 12.934%;
  min-width: 97px;
}

.tooth-11 {
  aspect-ratio: 177/532;
}

.crown-area-11 {
  aspect-ratio: 180/260;
}

.tooth-implant-11 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-implant-11 {
  width: 6.4%;
}

.tooth-baby-11 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-baby-11 {
  width: 6.4%;
}

.tooth-babyMissing-11 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-babyMissing-11 {
  width: 6.4%;
}

.tooth-implant-crownPart-11 {
  bottom: 6% !important;
}

.tooth-notYetDeveloped-11>img {
  top: 50.8%;
}

.crown-area-notYetDeveloped-11>img {
  top: 50%;
}

.root-rct-11 {
  position: absolute;
  top: 25%;
  left: 42%;
  transform: rotate(-5deg);
  width: 18%;
}

.root-rct_pu_ppf-11 {
  position: absolute;
  width: 60%;
  height: 11%;
  top: 55%;
  opacity: 0.8;
  left: 20%;
}

/* Tooth 12 */
.tooth-container-12 {
  width: 5.39%;
}

.tooth-container-large-12 {
  width: 10.78%;
  min-width: 81px;
}

.tooth-12 {
  aspect-ratio: 145/532;
}

.crown-area-12 {
  aspect-ratio: 150/260;
}

.tooth-implant-12 {
  aspect-ratio: 33.95 / 130.38;
}

.tooth-container-implant-12 {
  width: 5.3%;
}

.tooth-baby-12 {
  aspect-ratio: 33.95 / 130.36;
}

.tooth-container-baby-12 {
  width: 5.38%;
}

.tooth-babyMissing-12 {
  aspect-ratio: 33.95 / 130.36;
}

.tooth-container-babyMissing-12 {
  width: 5.38%;
}

.tooth-implant-crownPart-12 {
  bottom: 4% !important;
}

.tooth-notYetDeveloped-12>img {
  top: 51.7%;
}

.crown-area-notYetDeveloped-12>img {
  top: 50%;
}

.root-rct-12 {
  position: absolute;
  top: 30%;
  left: 39%;
  transform: translateX(-50%);
  width: 20%;
}

.root-rct_pu_ppf-12 {
  position: absolute;
  width: 53%;
  height: 11%;
  top: 58%;
  opacity: 0.8;
  left: 11.5%;
}

/* Tooth 13 */
.tooth-container-13 {
  width: 6.465%;
}

.tooth-container-large-13 {
  width: 12.93%;
  min-width: 84.3px;
}

.tooth-13 {
  aspect-ratio: 180/532;
}

.crown-area-13 {
  aspect-ratio: 180/260;
}

.tooth-implant-13 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-implant-13 {
  width: 6.4%;
}

.tooth-baby-13 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-baby-13 {
  width: 6.46%;
}

.tooth-babyMissing-13 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-babyMissing-13 {
  width: 6.46%;
}

.tooth-implant-crownPart-13 {
  bottom: 7.6% !important;
}

.tooth-notYetDeveloped-13>img {
  top: 49.9%;
}

.crown-area-notYetDeveloped-13>img {
  top: 50%;
}

.root-rct-13 {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translateX(-50%);
  width: 21%;
}

.root-rct_pu_ppf-13 {
  position: absolute;
  width: 60%;
  height: 11%;
  top: 60%;
  opacity: 0.8;
  left: 19.5%;
}

/* Tooth 14 */
.tooth-container-14 {
  width: 5.318%;
}

.tooth-container-large-14 {
  width: 10.636%;
  min-width: 84.3px;
}

.tooth-14 {
  aspect-ratio: 146/532;
}

.crown-area-14 {
  aspect-ratio: 148/260;
}

.tooth-implant-14 {
  aspect-ratio: 33.5 / 130.38;
}

.tooth-container-implant-14 {
  width: 5.3%;
}

.tooth-baby-14 {
  aspect-ratio: 33.5 / 130.36;
}

.tooth-container-baby-14 {
  width: 5.31%;
}

.tooth-babyMissing-14 {
  aspect-ratio: 33.5 / 130.36;
}

.tooth-container-babyMissing-14 {
  width: 5.31%;
}

.tooth-implant-crownPart-14 {
  bottom: 6% !important;
}

.tooth-notYetDeveloped-14>img {
  top: 50.6%;
}

.crown-area-notYetDeveloped-14>img {
  top: 50%;
}

.root-rct-14 {
  position: absolute;
  top: 26%;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
}

.root-rct_pu_ppf-14 {
  position: absolute;
  width: 66%;
  height: 11%;
  top: 61.5%;
  opacity: 0.8;
  left: 14.5%;
}

/* Tooth 15 */
.tooth-container-15 {
  width: 5.103%;
}

.tooth-container-large-15 {
  width: 10.206%;
  min-width: 66.54px;
}

.tooth-15 {
  aspect-ratio: 142/532;
}

.crown-area-15 {
  aspect-ratio: 142/260;
}

.tooth-implant-15 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-implant-15 {
  width: 5.1%;
}

.tooth-baby-15 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-baby-15 {
  width: 5.1%;
}

.tooth-babyMissing-15 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-babyMissing-15 {
  width: 5.1%;
}

.tooth-implant-crownPart-15 {
  bottom: 7.5% !important;
}

.tooth-notYetDeveloped-15>img {
  top: 50%;
}

.crown-area-notYetDeveloped-15>img {
  top: 50%;
}

.root-rct-15 {
  position: absolute;
  top: 28%;
  left: 53%;
  transform: translateX(-50%);
  width: 25%;
}

.root-rct_pu_ppf-15 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 62%;
  opacity: 0.8;
  left: 16.5%;
}

/* Tooth 16 */
.tooth-container-16 {
  width: 7.616%;
}

.tooth-container-large-16 {
  width: 15.232%;
  min-width: 99.31px;
}

.tooth-16 {
  aspect-ratio: 212/532;
}

.crown-area-16 {
  aspect-ratio: 212/260;
}

.tooth-implant-16 {
  aspect-ratio: 47.98 / 130.36;
}

.tooth-container-implant-16 {
  width: 7.6%;
}

.tooth-implant-crownPart-16 {
  bottom: 7.4% !important;
}

.tooth-notYetDeveloped-16>img {
  top: 50.04%;
}

.crown-area-notYetDeveloped-16>img {
  top: 50%;
}

.root-rct-16-right {
  position: absolute;
  top: 32%;
  right: 20%;
  transform: rotate(4deg);
  width: 20%;
}

.root-rct-16-middle {
  position: absolute;
  top: 22%;
  left: 51%;
  transform: translateX(-50%);
  width: 24%;
}

.root-rct-16-left {
  position: absolute;
  top: 31%;
  left: 21%;
  transform: rotate(-4deg);
  width: 20%;
}

.root-rct_pu_ppf-16 {
  position: absolute;
  width: 77%;
  height: 11%;
  top: 68%;
  opacity: 0.8;
  left: 9.5%;
}

/* Tooth 17 */
.tooth-container-17 {
  width: 7.042%;
}

.tooth-container-large-17 {
  width: 14.084%;
  min-width: 91.83px;
}

.tooth-17 {
  aspect-ratio: 196/532;
}

.crown-area-17 {
  aspect-ratio: 196/260;
}

.tooth-implant-17 {
  aspect-ratio: 44.36 / 130.36;
}

.tooth-container-implant-17 {
  width: 6.4%;
}

.tooth-implant-crownPart-17 {
  bottom: 14.6% !important;
}

.tooth-notYetDeveloped-17>img {
  top: 50.05%;
}

.crown-area-notYetDeveloped-17>img {
  top: 50%;
}

.root-rct-17-right {
  position: absolute;
  top: 31%;
  right: 19%;
  transform: rotate(3deg);
  width: 22%;
}

.root-rct-17-middle {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translateX(-50%);
  width: 23%;
}

.root-rct-17-left {
  position: absolute;
  top: 31%;
  left: 18%;
  transform: rotate(-1deg);
  width: 22%;
}

.root-rct_pu_ppf-17 {
  position: absolute;
  width: 72%;
  height: 11%;
  top: 63%;
  opacity: 0.8;
  left: 11.5%;
}

/* Tooth 18 */
.tooth-container-18 {
  width: 6.61%;
}

.tooth-container-large-18 {
  width: 13.22%;
  min-width: 86.19px;
}

.tooth-18 {
  aspect-ratio: 183/532;
}

.crown-area-18 {
  aspect-ratio: 184/260;
}

.tooth-implant-18 {
  aspect-ratio: 41.61 / 130.35;
}

.tooth-container-implant-18 {
  width: 6.5%;
}

.tooth-implant-crownPart-18 {
  bottom: 7% !important;
}

.tooth-notYetDeveloped-18>img {
  top: 50.4%;
}

.crown-area-notYetDeveloped-18>img {
  top: 50%;
}

.root-rct-18-right {
  position: absolute;
  top: 28%;
  right: 24%;
  transform: rotate(-7deg);
  width: 20%;
}

.root-rct-18-middle {
  position: absolute;
  top: 28%;
  left: 38%;
  transform: rotate(-3deg);
  width: 20%;
}

.root-rct-18-left {
  position: absolute;
  top: 28%;
  left: 21%;
  transform: rotate(-3deg);
  width: 20%;
}

.root-rct_pu_ppf-18 {
  position: absolute;
  width: 88%;
  height: 11%;
  top: 61%;
  opacity: 0.8;
  left: 5.5%;
}

/* Tooth 21 */
.root-rct-21 {
  position: absolute;
  top: 25%;
  left: 51%;
  transform: rotate(5deg);
  width: 18%;
}

.root-rct_pu_ppf-21 {
  position: absolute;
  width: 60%;
  height: 11%;
  top: 55%;
  opacity: 0.8;
  left: 22%;
}

/* Tooth 22 */
.root-rct-22 {
  position: absolute;
  top: 30%;
  left: 58%;
  transform: rotate(4deg);
  width: 20%;
}

.root-rct_pu_ppf-22 {
  position: absolute;
  width: 53%;
  height: 11%;
  top: 58%;
  opacity: 0.8;
  left: 34.5%;
}

/* Tooth 23 */
.root-rct-23 {
  position: absolute;
  top: 23%;
  left: 51%;
  transform: rotate(4deg);
  width: 21%;
}

.root-rct_pu_ppf-23 {
  position: absolute;
  width: 60%;
  height: 11%;
  top: 59%;
  opacity: 0.8;
  left: 22.5%;
}

/* Tooth 24 */
.root-rct-24 {
  position: absolute;
  top: 27%;
  left: 45%;
  transform: rotate(3deg);
  width: 25%;
}

.root-rct_pu_ppf-24 {
  position: absolute;
  width: 66%;
  height: 11%;
  top: 61.5%;
  opacity: 0.8;
  left: 15.5%;
}

/* Tooth 25 */
.root-rct-25 {
  position: absolute;
  top: 28%;
  left: 43%;
  transform: rotate(4deg);
  width: 25%;
}

.root-rct_pu_ppf-25 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 62%;
  opacity: 0.8;
  left: 14.5%;
}

/* Tooth 26 */
.root-rct-26-right {
  position: absolute;
  top: 32%;
  right: 15%;
  transform: rotate(7deg);
  width: 20%;
}

.root-rct-26-middle {
  position: absolute;
  top: 22%;
  left: 57%;
  transform: translateX(-50%);
  width: 24%;
}

.root-rct-26-left {
  position: absolute;
  top: 31%;
  left: 27%;
  transform: rotate(-3deg);
  width: 20%;
}

.root-rct_pu_ppf-26 {
  position: absolute;
  width: 77%;
  height: 11%;
  top: 68%;
  opacity: 0.8;
  left: 12.5%;
}

/* Tooth 27 */
.root-rct-27-right {
  position: absolute;
  top: 31%;
  right: 14%;
  transform: rotate(8deg);
  width: 22%;
}

.root-rct-27-middle {
  position: absolute;
  top: 28%;
  left: 48%;
  transform: rotate(4deg);
  width: 23%;
}

.root-rct-27-left {
  position: absolute;
  top: 31%;
  left: 27%;
  transform: rotate(0deg);
  width: 22%;
}

.root-rct_pu_ppf-27 {
  position: absolute;
  width: 72%;
  height: 11%;
  top: 63%;
  opacity: 0.8;
  left: 14.5%;
}

/* Tooth 28 */
.root-rct-28-right {
  position: absolute;
  top: 28%;
  right: 13%;
  transform: rotate(3deg);
  width: 20%;
}

.root-rct-28-middle {
  position: absolute;
  top: 28%;
  left: 48%;
  transform: rotate(3deg);
  width: 20%;
}

.root-rct-28-left {
  position: absolute;
  top: 28%;
  left: 28%;
  transform: rotate(7deg);
  width: 20%;
}

.root-rct_pu_ppf-28 {
  position: absolute;
  width: 91%;
  height: 11%;
  top: 61%;
  opacity: 0.8;
  left: 4.5%;
}

/* Tooth 31 */
.root-rct-31 {
  position: absolute;
  top: 39%;
  left: 41%;
  transform: rotate(181deg);
  width: 15%;
}

.root-rct_pu_ppf-31 {
  position: absolute;
  width: 43%;
  height: 11%;
  top: 29%;
  opacity: 0.8;
  left: 29%;
  transform: rotate(180deg);
}

/* Tooth 32 */
.root-rct-32 {
  position: absolute;
  top: 41%;
  left: 42%;
  transform: rotate(177deg);
  width: 19%;
}

.root-rct_pu_ppf-32 {
  position: absolute;
  width: 56%;
  height: 11%;
  top: 31%;
  opacity: 0.8;
  left: 22%;
  transform: rotate(180deg);
}

/* Tooth 33 */
.root-rct-33 {
  position: absolute;
  top: 41%;
  left: 40%;
  transform: rotate(181deg);
  width: 16%;
}

.root-rct_pu_ppf-33 {
  position: absolute;
  width: 57%;
  height: 11%;
  top: 32%;
  opacity: 0.8;
  left: 21%;
  transform: rotate(180deg);
}

/* Tooth 34 */
.root-rct-34 {
  position: absolute;
  top: 35%;
  left: 39%;
  transform: rotate(181deg);
  width: 24%;
}

.root-rct_pu_ppf-34 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 19%;
  transform: rotate(180deg);
}

/* Tooth 35 */
.root-rct-35 {
  position: absolute;
  top: 36%;
  left: 41%;
  transform: rotate(179deg);
  width: 29%;
}

.root-rct_pu_ppf-35 {
  position: absolute;
  width: 76%;
  height: 11%;
  top: 26%;
  opacity: 0.8;
  left: 15%;
  transform: rotate(180deg);
}

/* Tooth 36 */
.root-rct-36-right {
  position: absolute;
  top: 33%;
  right: 21%;
  transform: rotate(181deg);
  width: 19%;
}

.root-rct-36-left {
  position: absolute;
  top: 32%;
  left: 16%;
  transform: rotate(189deg);
  width: 19%;
}

.root-rct_pu_ppf-36 {
  position: absolute;
  width: 82%;
  height: 13%;
  top: 25%;
  opacity: 0.8;
  left: 12%;
  transform: rotate(180deg);
}

/* Tooth 37 */
.root-rct-37-right {
  position: absolute;
  top: 30%;
  right: 16%;
  transform: rotate(173deg);
  width: 19%;
}

.root-rct-37-left {
  position: absolute;
  top: 32%;
  left: 27%;
  transform: rotate(173deg);
  width: 19%;
}

.root-rct_pu_ppf-37 {
  position: absolute;
  width: 80%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 11%;
  transform: rotate(180deg);
}

/* Tooth 38 */
.root-rct-38-right {
  position: absolute;
  top: 29%;
  right: 27%;
  transform: rotate(181deg);
  width: 22%;
}

.root-rct-38-left {
  position: absolute;
  top: 29%;
  left: 16%;
  transform: rotate(185deg);
  width: 22%;
}

.root-rct_pu_ppf-38 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 11.5%;
  transform: rotate(180deg);
}

/* Tooth 41 */
.tooth-container-41 {
  width: 6.475%;
}

.tooth-container-large-41 {
  width: 12.95%;
  min-width: 84.43px;
}

.tooth-41 {
  aspect-ratio: 177/532;
}

.crown-area-41 {
  aspect-ratio: 180/260;
}

.tooth-implant-41 {
  aspect-ratio: 40.73 / 130.35;
}

.tooth-container-implant-41 {
  width: 6.4%;
}

.tooth-baby-41 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-baby-41 {
  width: 6.46%;
}

.tooth-babyMissing-41 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-babyMissing-41 {
  width: 6.46%;
}

.tooth-implant-crownPart-41 {
  top: 0 !important;
}

.tooth-notYetDeveloped-41>img {
  top: 49.2%;
}

.crown-area-notYetDeveloped-41>img {
  top: 50%;
}

.root-rct-41 {
  position: absolute;
  top: 39%;
  left: 41%;
  transform: rotate(181deg);
  width: 15%;
}

.root-rct_pu_ppf-41 {
  position: absolute;
  width: 43%;
  height: 11%;
  top: 29%;
  opacity: 0.8;
  left: 29%;
  transform: rotate(180deg);
}

/* Tooth 42 */
.tooth-container-42 {
  width: 5.398%;
}

.tooth-container-large-42 {
  width: 10.796%;
  min-width: 70.39px;
}

.tooth-42 {
  aspect-ratio: 145/532;
}

.crown-area-42 {
  aspect-ratio: 150/260;
}

.tooth-implant-42 {
  aspect-ratio: 33.95 / 130.36;
}

.tooth-container-implant-42 {
  width: 5.3%;
}

.tooth-baby-42 {
  aspect-ratio: 33.95 / 130.36;
}

.tooth-container-baby-42 {
  width: 5.3%;
}

.tooth-babyMissing-42 {
  aspect-ratio: 33.95 / 130.36;
}

.tooth-container-babyMissing-42 {
  width: 5.3%;
}

.tooth-implant-crownPart-42 {
  top: 0 !important;
}

.tooth-notYetDeveloped-42>img {
  top: 48.4%;
}

.crown-area-notYetDeveloped-42>img {
  top: 50%;
}

.root-rct-42 {
  position: absolute;
  top: 41%;
  left: 32%;
  transform: rotate(188deg);
  width: 19%;
}

.root-rct_pu_ppf-42 {
  position: absolute;
  width: 56%;
  height: 11%;
  top: 31%;
  opacity: 0.8;
  left: 22%;
  transform: rotate(180deg);
}

/* Tooth 43 */
.tooth-container-43 {
  width: 6.475%;
}

.tooth-container-large-43 {
  width: 12.95%;
  min-width: 84.43px;
}

.tooth-43 {
  aspect-ratio: 180/532;
}

.crown-area-43 {
  aspect-ratio: 180/260;
}

.tooth-implant-43 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-implant-43 {
  width: 6.4%;
}

.tooth-baby-43 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-baby-43 {
  width: 6.46%;
}

.tooth-babyMissing-43 {
  aspect-ratio: 40.73 / 130.36;
}

.tooth-container-babyMissing-43 {
  width: 6.46%;
}

.tooth-implant-crownPart-43 {
  top: 0 !important;
}

.tooth-notYetDeveloped-43>img {
  top: 50%;
}

.crown-area-notYetDeveloped-43>img {
  top: 50%;
}

.root-rct-43 {
  position: absolute;
  top: 41%;
  left: 40%;
  transform: rotate(181deg);
  width: 16%;
}

.root-rct_pu_ppf-43 {
  position: absolute;
  width: 57%;
  height: 11%;
  top: 32%;
  opacity: 0.8;
  left: 21%;
  transform: rotate(180deg);
}

/* Tooth 44 */
.tooth-container-44 {
  width: 5.325%;
}

.tooth-container-large-44 {
  width: 10.65%;
  min-width: 69.44px;
}

.tooth-44 {
  aspect-ratio: 146/532;
}

.crown-area-44 {
  aspect-ratio: 148/260;
}

.tooth-implant-44 {
  aspect-ratio: 33.5 / 130.36;
}

.tooth-container-implant-44 {
  width: 5.3%;
}

.tooth-baby-44 {
  aspect-ratio: 33.5 / 130.36;
}

.tooth-container-baby-44 {
  width: 5.3%;
}

.tooth-babyMissing-44 {
  aspect-ratio: 33.5 / 130.36;
}

.tooth-container-babyMissing-44 {
  width: 5.3%;
}

.tooth-implant-crownPart-44 {
  top: 0 !important;
}

.tooth-notYetDeveloped-44>img {
  top: 49.4%;
}

.crown-area-notYetDeveloped-44>img {
  top: 50%;
}

.root-rct-44 {
  position: absolute;
  top: 35%;
  left: 31%;
  transform: rotate(181deg);
  width: 24%;
}

.root-rct_pu_ppf-44 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 11%;
  transform: rotate(180deg);
}

/* Tooth 45 */
.tooth-container-45 {
  width: 5.11%;
}

.tooth-container-large-45 {
  width: 10.22%;
  min-width: 66.63px;
}

.tooth-45 {
  aspect-ratio: 142/532;
}

.crown-area-45 {
  aspect-ratio: 142/260;
}

.tooth-implant-45 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-implant-45 {
  width: 5.1%;
}

.tooth-baby-45 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-baby-45 {
  width: 5.1%;
}

.tooth-babyMissing-45 {
  aspect-ratio: 32.14 / 130.36;
}

.tooth-container-babyMissing-45 {
  width: 5.1%;
}

.tooth-implant-crownPart-45 {
  top: 0 !important;
}

.tooth-notYetDeveloped-45>img {
  top: 50%;
}

.crown-area-notYetDeveloped-45>img {
  top: 50%;
}

.root-rct-45 {
  position: absolute;
  top: 36%;
  left: 28%;
  transform: rotate(185deg);
  width: 29%;
}

.root-rct_pu_ppf-45 {
  position: absolute;
  width: 76%;
  height: 11%;
  top: 26%;
  opacity: 0.8;
  left: 13%;
  transform: rotate(180deg);
}

/* Tooth 46 */
.tooth-container-46 {
  width: 7.649%;
}

.tooth-container-large-46 {
  width: 15.298%;
  min-width: 99.74px;
}

.tooth-46 {
  aspect-ratio: 212/532;
}

.crown-area-46 {
  aspect-ratio: 212/260;
}

.tooth-implant-46 {
  aspect-ratio: 47.98 / 130.36;
}

.tooth-container-implant-46 {
  width: 7.6%;
}

.tooth-implant-crownPart-46 {
  top: 0 !important;
}

.tooth-notYetDeveloped-46>img {
  top: 49.7%;
}

.crown-area-notYetDeveloped-46>img {
  top: 50%;
}

.root-rct-46-right {
  position: absolute;
  top: 34%;
  right: 17%;
  transform: rotate(177deg);
  width: 19%;
}

.root-rct-46-left {
  position: absolute;
  top: 34%;
  left: 20%;
  transform: rotate(184deg);
  width: 19%;
}

.root-rct_pu_ppf-46 {
  position: absolute;
  width: 82%;
  height: 13%;
  top: 25%;
  opacity: 0.8;
  left: 11%;
  transform: rotate(180deg);
}

/* Tooth 47 */
.tooth-container-47 {
  width: 7.0723%;
}

.tooth-container-large-47 {
  width: 14.1446%;
  min-width: 92.21px;
}

.tooth-47 {
  aspect-ratio: 196/532;
}

.crown-area-47 {
  aspect-ratio: 196/260;
}

.tooth-implant-47 {
  aspect-ratio: 44.36 / 130.36;
}

.tooth-container-implant-47 {
  width: 7%;
}

.tooth-implant-crownPart-47 {
  top: 0 !important;
}

.tooth-notYetDeveloped-47>img {
  top: 49.5%;
}

.crown-area-notYetDeveloped-47>img {
  top: 50%;
}

.root-rct-47-right {
  position: absolute;
  top: 32%;
  right: 34%;
  transform: rotate(185deg);
  width: 19%;
}

.root-rct-47-left {
  position: absolute;
  top: 31%;
  left: 7%;
  transform: rotate(188deg);
  width: 19%;
}

.root-rct_pu_ppf-47 {
  position: absolute;
  width: 80%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 8%;
  transform: rotate(180deg);
}

/* Tooth 48 */
.tooth-container-48 {
  width: 6.637%;
}

.tooth-container-large-48 {
  width: 13.346%;
  min-width: 87.01px;
}

.tooth-48 {
  aspect-ratio: 183/532;
}

.crown-area-48 {
  aspect-ratio: 184/260;
}

.tooth-implant-48 {
  aspect-ratio: 41.64 / 130.36;
}

.tooth-container-implant-48 {
  width: 6.5%;
}

.tooth-implant-crownPart-48 {
  top: 0 !important;
}

.tooth-notYetDeveloped-48>img {
  top: 49.5%;
}

.crown-area-notYetDeveloped-48>img {
  top: 50%;
}

.root-rct-48-right {
  position: absolute;
  top: 29%;
  right: 21%;
  transform: rotate(181deg);
  width: 22%;
}

.root-rct-48-left {
  position: absolute;
  top: 29%;
  left: 24%;
  transform: rotate(180deg);
  width: 22%;
}

.root-rct_pu_ppf-48 {
  position: absolute;
  width: 70%;
  height: 11%;
  top: 25%;
  opacity: 0.8;
  left: 19.5%;
  transform: rotate(180deg);
}

/* Common */

.tooth-notYetDeveloped>img {
  min-height: 40px;
  max-height: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
}

.crown-area-notYetDeveloped>img {
  min-height: 40px;
  max-height: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Colors */

.purple>img {
  filter: brightness(1) saturate(20) invert(21%) sepia(2%) hue-rotate(227deg) brightness(100%) contrast(80%);
}

.sky-blue>img {
  filter: brightness(1) saturate(10) invert(13%) sepia(14%) hue-rotate(153deg) brightness(97%) contrast(90%);
}

.grey>img {
  filter: brightness(1) saturate(0%) invert(17%) sepia(5%) saturate(4%) hue-rotate(125deg) brightness(92%) contrast(100%);
}

.yellow>img {
  filter: brightness(1) saturate(8%) invert(20%) sepia(64%) saturate(159%) hue-rotate(384deg) brightness(100%) contrast(100%);
}

.green>img {
  filter: brightness(1) saturate(16) invert(11%) sepia(2%) hue-rotate(442deg) brightness(100%) contrast(82%);
}

.transparent>img {
  opacity: 0;
}

.celeste>img {
  filter: brightness(1) saturate(93%) invert(18%) sepia(123%) saturate(2102%) hue-rotate(176deg) brightness(100%) contrast(95%);
}

.x-tooth::after,
.x-tooth::before {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 2px;
  background-color: red;
  z-index: 99;
}

.x-tooth::after {
  transform: rotate(10deg);
  right: 50%;
}

.x-tooth::before {
  left: 50%;
  transform: rotate(-10deg);
}

.hide {
  visibility: hidden;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{
  z-index: 10000 !important;
}