/* YourComponent.css */

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

.logiForm {
  width: calc(100% - 30px);
  max-width: 570px;
  margin: auto;
  background: #fff;
  padding: 70px 20px 20px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
  text-align: center;
  position: relative;
  z-index: 1;
}

.login-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.titleInfo .title {
  font-size: 40px;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  letter-spacing: 1px;
  margin: 0 0 15px;
}

.titleInfo p {
  font-size: 16px;
  margin: 0;
}

.titleInfo {
  margin: 0 0 30px;
}

.loginInput {
  width: 100%;
  max-width: 320px;
  margin: 0 auto 40px;
  position: relative;
}

.loginInput input {
  height: 40px;
  border: 1px solid #56baed;
  width: 100%;
  padding: 10px 25px;
  border-radius: 30px;
  padding-right: 65px;
  outline: 0;
  transition: all 0.3s ease-in-out;
}

.loginInput button {
  background-color: #56baed;
  border: 0;
  padding: 0.8em;
  width: 90%;
  border-radius: 40px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 20px 20px 20px;
  transition: all 0.3s ease-in-out;
}

.brandLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 0 75px;
}

.brandLogo img {
  width: 150px;
}

.brandLogo h6 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
}

.noteLine {
  font-size: 14px;
  margin: 0;
}

.loginInput button:hover {
  background: #2a93c9;
}

.login-wrapper:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(86 186 237 / 40%);
  top: 0;
  left: 0;
}